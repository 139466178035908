import {
  backend,
  beecode,
  creator,
  css,
  docker,
  figma,
  fiverr,
  git,
  html,
  ismile,
  javascript,
  meditation,
  mobile,
  mongodb,
  motive,
  nodejs,
  reactjs,
  redux,
  swipen,
  tailwind,
  threejs,
  typescript,
  web,
  youthzone
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Web Developer",
    icon: web,
  },
  {
    title: "React Native Developer",
    icon: mobile,
  },
  {
    title: "Backend Developer",
    icon: backend,
  },
  {
    title: "Deployment & Maintenance",
    icon: creator,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
  {
    name: "docker",
    icon: docker,
  },
];

const experiences = [
  {
    title: "Full Stack Web & Mobile Developer",
    company_name: "Bee Code",
    icon: beecode,
    iconBg: "#FFF",
    link: "https://beecode.es/",
    date: "Aug 2021 - Present",
    points: [
      "Developing and maintaining web & mobile applications using React, React Native, Node and other related technologies.",
      "Working as a one man army to develop, deploy, fix and maintene web and mobile app :)",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
  {
    title: "Full Stack Web & Mobile Developer | Side Hustle",
    company_name: "Fiverr",
    icon: fiverr,
    link: "https://www.fiverr.com/abdulqadiragha",
    iconBg: "#f7f7f7",
    date: "March 2020 - Present",
    points: [
      "Achieving an outstanding track record of 20+ 5-star reviews on Fiverr, a testament to the exceptional quality and satisfaction of clients.",
      "Leveraging Fiverr as a side hustle to actively expand skills and broaden professional connections.",
      "Mastery in developing, developing and maintaining dynamic web and mobile applications, offering full stack expertise.",
      "Collaborating with cross-functional teams, to create top-tier products",
      "Implementing responsive design principles to ensure seamless user experiences and cross-browser compatibility.",
    ],
  },
  {
    title: "Full stack Developer",
    company_name: "Motive",
    link: "https://motive-app.co.uk/",
    icon: motive,
    iconBg: "#E6DEDD",
    date: "Oct 2022 - Jan 2023 ",
    points: [
      "Developing web applications using React.js and Node.js with other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing code princeples, responsive design and ensuring cross-browser compatibility.",
      "Participating in project and code reviews and providing constructive feedback to team.",
    ],
  },
  {
    title: "React Developer",
    company_name: "SwipenOfficial",
    icon: swipen,
    link: "https://swipen.co.uk/",
    iconBg: "#FFF",
    date: "May 2022 - Aug 2022",
    points: [
      "Developing web applications using React.js and other related technologies.",
      "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
      "Implementing responsive design and ensuring cross-browser compatibility.",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
  },
];

const testimonials = [
  {
    testimonial:
      "I thought it was impossible to make a website as beautiful as our product, but Rick proved me wrong.",
    name: "Sara Lee",
    designation: "CFO",
    company: "Acme Co",
    image: "https://randomuser.me/api/portraits/women/4.jpg",
  },
  {
    testimonial:
      "I've never met a web developer who truly cares about their clients' success like Rick does.",
    name: "Chris Brown",
    designation: "COO",
    company: "DEF Corp",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
  },
  {
    testimonial:
      "After Rick optimized our website, our traffic increased by 50%. We can't thank them enough!",
    name: "Lisa Wang",
    designation: "CTO",
    company: "456 Enterprises",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
  },
];

const projects = [
  {
    name: "Meditaciones Diarias",
    description:
      "This is a free app that allows you to listen to hundreds of meditations, ranging from 15 to 30 minutes in duration. I have develop this one for BeeCode.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "react native",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "pink-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "dashbaord",
        color: "green-text-gradient",
      },
    ],
    image: meditation,
    source_code_link:
      "https://apps.apple.com/us/app/meditaciones-diarias/id1621721684",
  },
  {
    name: "iSmile",
    description:
      "This is a mobile app that allows you to control your meditation, get quotes and schedule meditation, I have develop this one for BeeCode.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "react native",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "pink-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "dashbaord",
        color: "green-text-gradient",
      },
    ],
    image: ismile,
    source_code_link: "",
  },
  {
    name: "Youth Zone | Final Year Project",
    description:
      "This is a mobile app with an advanced web dashboard where users can make friends, chat with friends, join competitions, challenge friends, enroll in free courses, and much more. I am looking forward to finding a sponsor for this project and making it live. Feel free to contact me if you are interested! :)",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "react native",
        color: "blue-text-gradient",
      },
      {
        name: "nodejs",
        color: "pink-text-gradient",
      },
      {
        name: "mongodb",
        color: "green-text-gradient",
      },
      {
        name: "dashbaord",
        color: "green-text-gradient",
      },
    ],
    image: youthzone,
    source_code_link: "",
  },
];

export { services, technologies, experiences, testimonials, projects };
